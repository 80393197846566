import { render, staticRenderFns } from "./EasyUse.vue?vue&type=template&id=0a53a65a&scoped=true"
import script from "./EasyUse.js?vue&type=script&lang=js&external"
export * from "./EasyUse.js?vue&type=script&lang=js&external"
import style0 from "./EasyUse.scss?vue&type=style&index=0&id=0a53a65a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a53a65a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default,HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default,HotOrganicButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Organic/Button/Button.vue').default})
